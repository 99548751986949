export enum LangType {
    FR = "FR",
    EN = "EN",
    ES = "ES",
    DE = "DE",
    PT = "PT",
    IT = "IT",
    PL = "PL",
    NL = "NL",
    RO = "RO",
    CS = "CS",
    HU = "HU",
    SV = "SV",
    EL = "EL",
    BG = "BG",
    DA = "DA",
    FI = "FI",
    SK = "SK",
    HR = "HR",
    LI = "LI",
    SL = "SL",
    LV = "LV",
    EE = "EE",
}
export const LangTradingView: string[] = [
    "en",
    "in",
    "de_DE",
    "fr",
    "es",
    "it",
    "pl",
    "sv_SE",
    "tr",
    "ru",
    "br",
    "id",
    "ms_MY",
    "th_TH",
    "vi_VN",
    "ja",
    "kr",
    "zh_CN",
    "zh_TW",
    "ar_AE",
    "he_IL",
];
