import React, { useEffect, useMemo, useState } from "react";
import { UserType } from "../../../../Models/User";
import useAuthentication from "../../../../Services/Authentication/useAuthentication";
import translator from "../../../Extra/Translation/Translate";
import HelmetComponent from "../../../../Admin/Layout/HelmetComponent/HelmetComponent";
import { NavLink } from "react-router-dom";
import CheckPriceSvg from "../../../Extra/Svg/CheckPriceSvg";
import CheckNonePriceSvg from "../../../Extra/Svg/CheckNonePriceSvg";
import FidelityModel from "../../../../Models/Fidelity";
import FidelityService from "../../../../Services/FidelityService/FidelityService";
import SettingService from "../../../../Services/Setting/SettingService";
import UtilsService from "../../../../Services/UtilsService";
import { BaseCurrency } from "../../../../Models/BaseCurrency";

const Subscription = () => {
    const { translate } = translator();
    const [card, setCard] = useState<FidelityModel | undefined>();
    const { user } = useAuthentication();
    const [isMobile, setIsMobile] = useState(false);
    const isParticular = user?.type === UserType.PARTICULIER;
    const isCompany = user?.type === UserType.ENTERPRISE;

    const getLastCard = async () => {
        try {
            const res = await FidelityService.getLastLoyalty();
            setCard(res);
        } catch (error) {
            console.log("erreur lastcard");
        }
    };

    const [baseCurrency, setBaseCurrency] = useState<any>(null);

    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();
    const [dataSubcription, setDataSubcription] = useState<any | undefined>();
    // const getLoyalty = async () => {
    //     try {
    //         const res = await FidelityService.getLastValidateCard();
    //         setCurrentLoyalty(res);
    //     } catch (err: any) {}
    // };

    const getActiveSubcription = async () => {
        try {
            const res = await FidelityService.getActiveFidelity();
            setDataSubcription(res);
            setCurrentLoyalty(res?.fidelity);
        } catch (err: any) {}
    };

    const getBaseCurrency = async () => {
        const res = await SettingService.getBaseCurrency();
        setBaseCurrency(res);
    };

    const limit_condition = useMemo<boolean>(() => {
        return baseCurrency && currentLoyalty && user;
    }, [baseCurrency, currentLoyalty, user]);

    useEffect(() => {
        getBaseCurrency();
        getLastCard();
        // getLoyalty();
        getActiveSubcription();
    }, []);
    const translatedSubscription = useMemo(() => {
        switch (dataSubcription?.type) {
            case "annually":
                return translate("SUBSCRIPTION", "Subs_Annual");
            case "monthly":
                return translate("SUBSCRIPTION", "Subs_Mois");
            case "trimestrial":
                return translate("SUBSCRIPTION2", "trimestrial");
            case "semestriel":
                return translate("SUBSCRIPTION2", "semestriel");
            default:
                return translate("SUBSCRIPTION", "");
        }
    }, [dataSubcription?.type]);

    return (
        <>
            <HelmetComponent
                title={translate("SUBSCRIPTION", "Subscription")}
                description={translate("SUBSCRIPTION", "Subscription")}
                link="/subscription"
                keywords=""
                imageCard="/dist/image/Infinexia-cards-1024x1024.png"
                largeTwitterCard="/dist/image/Infinexia-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={true}
            ></HelmetComponent>
            <div className="intro-y mt-5 md:!mt-5 mb-2 items-center mx-0 px-0">
                <h2 className="mr-auto text-2xl font-medium mx-0 px-0">
                    {translate("SUBSCRIPTION", "My_Subscription")}
                </h2>
            </div>
            <div className="intro-y mt-3">
                <div className="box px-4 mx-0 py-2 md:!py-0 mb-3 dark:!border-2 dark:!border-slate-700">
                    <div className="md-flex justify-between items-center py-6 md:!py-6 px-2 w-full">
                        <div className="flex items-center w-full sm:h-[50px]">
                            <div className="w-full">
                                <div className="text-center md:!text-left font-bold text-lg">
                                    {translate("SUBSCRIPTION", "Subscription")}
                                    {""}
                                    {currentLoyalty
                                        ? currentLoyalty.name
                                        : translate("SUBSCRIPTION", "Free")}
                                </div>
                                <div className="text-center md:!text-left text-base text-gray-500">
                                    {currentLoyalty
                                        ? card && baseCurrency
                                            ? card.is_text_price === false
                                                ? UtilsService.getConvertedFormatedAmount2(
                                                      currentLoyalty.price,
                                                      baseCurrency,
                                                      user?.base_currency,
                                                      2,
                                                  ).replace(".", ",")
                                                : currentLoyalty.price
                                            : "0 €"
                                        : "0 €"}{" "}
                                    /{translatedSubscription}
                                </div>
                                <div className="text-center md:!text-left mt-1 text-sm ">
                                    {translate(
                                        "SUBSCRIPTION",
                                        currentLoyalty
                                            ? "My_Plan"
                                            : "Subscribe_text",
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex justify-between md:mt text-center">
                            <NavLink to="/cards">
                                <button
                                    type="button"
                                    className="btn btn-perfect mt-2 w-48"
                                >
                                    {translate(
                                        "SUBSCRIPTION",
                                        currentLoyalty &&
                                            (currentLoyalty.name === "Blue" ||
                                                currentLoyalty.name === "Gold")
                                            ? "Upgrade"
                                            : "Modif_subs",
                                    )}
                                </button>
                            </NavLink>
                            {currentLoyalty && (
                                <NavLink to="/my-subscription">
                                    <button
                                        type="button"
                                        className="btn btn-perfect mt-2 w-25 ml-3"
                                    >
                                        {translate("SUBSCRIPTION", "Gérer")}
                                    </button>
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden max-w-sm mx-auto lg:max-w-none mt-2 pt-1 pb-1 px-0">
                <div className="flex overflow-x-auto scrollbar-none mx-auto px-1 justify-center">
                    <NavLink to="/cards">
                        <button
                            type="button"
                            className="btn btn-perfect actif w-48 text-sm text-white rounded-full !border-none hover:border-none"
                        >
                            {translate(
                                "SUBSCRIPTION",
                                currentLoyalty &&
                                    (currentLoyalty.name === "Blue" ||
                                        currentLoyalty.name === "Gold")
                                    ? "Upgrade"
                                    : "Modif_subs",
                            )}
                        </button>
                    </NavLink>
                    {currentLoyalty && (
                        <NavLink to="/my-subscription">
                            <button
                                type="button"
                                className="btn btn-perfect actif text-sm text-white rounded-full !border-none hover:border-none ml-3"
                            >
                                {translate("SUBSCRIPTION", "Gérer")}
                            </button>
                        </NavLink>
                    )}
                </div>
            </div>
            {/* Second section */}
            {currentLoyalty && (
                <div>
                    <div className="intro-y mt-5 md:!mt-7 mb-2 items-center mx-0 px-0">
                        <h3 className="mr-auto text-lg font-medium !capitalize mx-0 px-0 ml-1">
                            {translate("PRICES_PAGE", "Limites")}
                        </h3>
                    </div>
                    <div className="mt-2 mx-0 px-0"></div>
                    <div className="box px-4 mx-0 py-2 dark:!border-2 dark:!border-slate-700">
                        {baseCurrency && (
                            <>
                                <div className="md-flex justify-between items-center py-0 md:!py-2 px-2 w-full">
                                    <div className="flex items-center w-full sm:h-[60px] md:h-auto">
                                        <div className="block w-full pl-2">
                                            <div className="text-sm md:!text-base font-medium">
                                                {translate(
                                                    "Fidelity",
                                                    "payment_monthly_limit",
                                                )}
                                            </div>
                                            <div className="text-sm text-slate-500">
                                                {limit_condition
                                                    ? UtilsService.getConvertedFormatedAmount2(
                                                          currentLoyalty.paymentMonthlyLimit,
                                                          baseCurrency,
                                                          user?.base_currency,
                                                          0,
                                                      )
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md-flex justify-between items-center py-0 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                                    <div className="flex items-center w-full sm:h-[60px] md:h-auto">
                                        <div className="block pl-2 w-full">
                                            <div className="text-sm md:!text-base font-medium">
                                                {translate(
                                                    "Fidelity",
                                                    "withdrawal_monthly_limit",
                                                )}
                                            </div>
                                            <div className="text-sm text-slate-500">
                                                {limit_condition
                                                    ? UtilsService.getConvertedFormatedAmount2(
                                                          currentLoyalty.withdrawalMonthlyLimit,
                                                          baseCurrency,
                                                          user?.base_currency,
                                                          0,
                                                      )
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {/* First section */}
            <div className="intro-y mt-10 md:!mt-7 mb-2 items-center mx-0 px-0">
                <h3 className="mr-auto text-lg font-medium !capitalize mx-0 px-0 ml-1">
                    {translate("PRICES_PAGE", "Features")}
                </h3>
            </div>
            <div className="mt-2 mx-0 px-0"></div>
            <div className="box px-4 mx-0 dark:!border-2 dark:!border-slate-700">
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("PRICES_PAGE", "Account_With_IBAN")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {translate("SUBSCRIPTION", "Account_IBAN_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("PRICES_PAGE", "Free_Bank_Card")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {translate("SUBSCRIPTION", "Card_Free_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <CheckPriceSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Transfert_Inf")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {translate(
                                    "SUBSCRIPTION",
                                    "Transfert_Inf_Desc",
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <CheckPriceSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Currency_Ex")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {translate("SUBSCRIPTION", "Currency_Ex_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <CheckPriceSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Safe")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {translate("SUBSCRIPTION", "Safe_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                {isParticular && (
                    <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                        <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                            <div className="w-6 p-1">
                                <CheckPriceSvg />
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base font-medium">
                                    {translate(
                                        "SUBSCRIPTION",
                                        "Jackpot_Friends",
                                    )}
                                </div>
                                <div className="text-sm text-slate-500">
                                    {translate(
                                        "SUBSCRIPTION",
                                        "Jackpot_Friends_Desc",
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            <CheckPriceSvg />
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Savings_Plan")}
                            </div>
                            <div className="text-sm md:!text-sm text-slate-500">
                                {translate("SUBSCRIPTION", "Savings_Plan_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                {isCompany && (
                    <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                        <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                            <div className="w-6 p-1">
                                {currentLoyalty ? (
                                    <CheckPriceSvg />
                                ) : (
                                    <CheckNonePriceSvg />
                                )}
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base font-medium">
                                    {translate("PRICES_PAGE", "Pay_Link")}
                                </div>
                                <div className="text-sm md:!text-sm text-slate-500">
                                    {translate("SUBSCRIPTION", "Pay_Link_Desc")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isCompany && (
                    <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                        <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                            <div className="w-6 p-1">
                                {currentLoyalty &&
                                (currentLoyalty.name === "Gold" ||
                                    currentLoyalty.name === "Black") ? (
                                    <CheckPriceSvg />
                                ) : (
                                    <CheckNonePriceSvg />
                                )}
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base font-medium">
                                    {translate("SUBSCRIPTION", "Devisfact")}
                                </div>
                                <div className="text-sm md:!text-sm text-slate-500">
                                    {translate(
                                        "SUBSCRIPTION",
                                        "Devisfact_Desc",
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isCompany && (
                    <div className="md-flex justify-between items-center py-1 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                        <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                            <div className="w-6 p-1">
                                {currentLoyalty &&
                                (currentLoyalty.name === "Gold" ||
                                    currentLoyalty.name === "Black") ? (
                                    <CheckPriceSvg />
                                ) : (
                                    <CheckNonePriceSvg />
                                )}
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base font-medium">
                                    {translate("SUBSCRIPTION", "Api")}
                                </div>
                                <div className="text-sm md:!text-sm text-slate-500">
                                    {translate("SUBSCRIPTION", "Api_Desc")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Second section */}
            <div className="intro-y mt-10 md:!mt-7 mb-2 items-center mx-0 px-0">
                <h3 className="mr-auto text-lg font-medium !capitalize mx-0 px-0 ml-1">
                    {translate("PRICES_PAGE", "Benefits")}
                </h3>
            </div>
            <div className="mt-2 mx-0 px-0"></div>
            <div className="box px-4 mx-0 mb-5 dark:!border-2 dark:!border-slate-700">
                {isCompany && (
                    <div className="md-flex justify-between items-center py-3 md:!pt-3 md:!pb-2 px-2 w-full border-b !border-b-slate-400/60  dark:!border-b dark:!border-slate-700">
                        <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                            <div className="w-6 p-1">
                                {currentLoyalty &&
                                (currentLoyalty.name === "Gold" ||
                                    currentLoyalty.name === "Black") ? (
                                    <CheckPriceSvg />
                                ) : (
                                    <CheckNonePriceSvg />
                                )}
                            </div>
                            <div className="block pl-8 w-full">
                                <div className="text-sm md:!text-base font-medium">
                                    {translate("SUBSCRIPTION", "Api_Fees")}
                                </div>
                                <div className="text-sm text-slate-500">
                                    {currentLoyalty &&
                                    currentLoyalty.name === "Gold" ? (
                                        <>
                                            {translate(
                                                "SUBSCRIPTION",
                                                "Api_Fees_Desc",
                                            )}{" "}
                                            <span className="font-bold">
                                                2%
                                            </span>
                                        </>
                                    ) : currentLoyalty &&
                                      currentLoyalty.name === "Black" ? (
                                        <>
                                            {translate(
                                                "SUBSCRIPTION",
                                                "Api_Fees_Desc",
                                            )}{" "}
                                            <span className="font-bold">
                                                1,5%
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            {translate(
                                                "SUBSCRIPTION",
                                                "Api_Fees_At",
                                            )}{" "}
                                            <span className="font-bold">
                                                1,5%
                                            </span>
                                        </>
                                    )}{" "}
                                    {translate("SUBSCRIPTION", "Api_Fees_Pay")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="md-flex justify-between items-center py-2 md:!py-2 px-2 w-full">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty &&
                            (currentLoyalty.name === "Gold" ||
                                currentLoyalty.name === "Black") ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Reduc_Swap")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {currentLoyalty &&
                                currentLoyalty.name === "Gold" ? (
                                    <>
                                        {translate(
                                            "SUBSCRIPTION",
                                            "Reduc_Desc",
                                        )}{" "}
                                        <span className="font-bold">25%</span>
                                    </>
                                ) : currentLoyalty &&
                                  currentLoyalty.name === "Black" ? (
                                    <>
                                        {translate(
                                            "SUBSCRIPTION",
                                            "Reduc_Desc",
                                        )}{" "}
                                        <span className="font-bold">50%</span>
                                    </>
                                ) : (
                                    <>
                                        {translate("SUBSCRIPTION", "Reduc_At")}{" "}
                                        <span className="font-bold">50%</span>
                                    </>
                                )}{" "}
                                {translate("SUBSCRIPTION", "Reduc_Swap_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-2 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty &&
                            (currentLoyalty.name === "Gold" ||
                                currentLoyalty.name === "Black") ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Reduc_Fees")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {currentLoyalty &&
                                currentLoyalty.name === "Gold" ? (
                                    <>
                                        {translate(
                                            "SUBSCRIPTION",
                                            "Reduc_Desc",
                                        )}{" "}
                                        <span className="font-bold">25%</span>
                                    </>
                                ) : currentLoyalty &&
                                  currentLoyalty.name === "Black" ? (
                                    <>
                                        {translate(
                                            "SUBSCRIPTION",
                                            "Reduc_Desc",
                                        )}{" "}
                                        <span className="font-bold">50%</span>
                                    </>
                                ) : (
                                    <>
                                        {translate("SUBSCRIPTION", "Reduc_At")}{" "}
                                        <span className="font-bold">50%</span>
                                    </>
                                )}{" "}
                                {translate("SUBSCRIPTION", "Reduc_Fees_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-2 md:!py-2 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-[80px] md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty &&
                            currentLoyalty.name === "Black" ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Cashback_Swap")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {currentLoyalty &&
                                currentLoyalty.name === "Black"
                                    ? translate("SUBSCRIPTION", "Reduc_Desc")
                                    : translate(
                                          "SUBSCRIPTION",
                                          "Reduc_At",
                                      )}{" "}
                                <span className="font-bold">1%</span>{" "}
                                {translate(
                                    "SUBSCRIPTION",
                                    "Cashback_Swap_Desc",
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md-flex justify-between items-center py-4 md:!pt-2 md:!pb-3 px-2 w-full border-t !border-t-slate-400/60  dark:!border-t dark:!border-slate-700">
                    <div className="flex items-center w-full sm:h-auto md:h-auto">
                        <div className="w-6 p-1">
                            {currentLoyalty &&
                            currentLoyalty.name === "Black" ? (
                                <CheckPriceSvg />
                            ) : (
                                <CheckNonePriceSvg />
                            )}
                        </div>
                        <div className="block pl-8 w-full">
                            <div className="text-sm md:!text-base font-medium">
                                {translate("SUBSCRIPTION", "Cashback_Cb")}
                            </div>
                            <div className="text-sm text-slate-500">
                                {currentLoyalty &&
                                currentLoyalty.name === "Black"
                                    ? translate("SUBSCRIPTION", "Reduc_Desc")
                                    : translate(
                                          "SUBSCRIPTION",
                                          "Reduc_At",
                                      )}{" "}
                                <span className="font-bold">1%</span>{" "}
                                {translate("SUBSCRIPTION", "Cashback_Cb_Desc")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Subscription;
