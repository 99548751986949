import React, { useRef } from "react";
import translator from "../Extra/Translation/Translate";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CheckPriceSvg from "../Extra/Svg/CheckPriceSvg";
import LockPriceSvg from "../Extra/Svg/LockPriceSvg";
import CheckNonePriceSvg from "../Extra/Svg/CheckNonePriceSvg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import CircleCheckSvg from "../Extra/Svg/CircleCheckSvg";
import CreditCardSvg from "../Extra/Svg/CreditCardSvg";
import CircleMinusSvg from "../Extra/Svg/CircleMinusSvg";
import ChevronsDownPricesSvg from "../Extra/Svg/ChevronsDownPricesSvg";

const Card_Blue = "/dist/image/Business Blue.png";
const Card_Gold = "/dist/image/Business Gold.png";
const Card_Black = "/dist/image/Business Black.png";

interface BusinessPriceCardsProps {
    active: "blue" | "black" | "gold";
    handleButtonClick: (color: "blue" | "gold" | "black") => void;
    handleSlideChange: (swiper: SwiperType) => void;
    disableDarkMode?: boolean;
}

const BusinessPriceCards: React.FC<BusinessPriceCardsProps> = ({
    active,
    handleButtonClick,
    handleSlideChange,
    disableDarkMode,
}) => {
    const { translate } = translator();
    const { lang } = useParams();
    const swiperRef = useRef<SwiperType | null>(null);

    return (
        <div className=" bg-[#191c1e] pt-[5px] md:!pt-[75px] pb-[5px] md:!pb-[0px]">
            <div className=" grid  grid-cols-1 lg:!grid-cols-3 w-[96%] max-w-[1280px] mx-auto  py-6 md:text-lg  sm:text-base  grid-flow-row gap-6 ">
                <div
                    className={`pb-[5px] rounded-[10px] mt-[30px] border !border-[#bebebe] bg-white ${
                        active == "blue" ? "" : "hidden md:!block"
                    } `}
                >
                    <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                        <h3 className="text-xl md:!text-2xl text-white font-medium">
                            Blue
                        </h3>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Blue}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <p className=" text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    9,90 €
                                </span>
                                / {translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className="text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block pt-[10px] pb-[0px] text-left text-base md:!text-lg font-bold md:!px-[50px] px-[40px]">
                        <div className="mb-3">
                            <CreditCardSvg />
                            {translate(
                                "PRICES_PAGE",
                                "Blue_Bank_Card_And_IBAN",
                            )}
                        </div>
                        <p className="text-left text-base font-normal">
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Pay_Link")}
                            </div>
                            <div className="mb-3">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Devis_Factures")}
                            </div>
                            <div className="mb-3">
                                <CircleMinusSvg />
                                {translate("DOC_API_PAGE", "API_Service")}
                            </div>
                            <div className="mb-3">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Réduc_frais")}
                            </div>
                            <div className="mb-1">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Cashback_opé")}
                            </div>
                        </p>
                    </div>
                    <div className="mt-[0px] mb-[0px] tracking-wide text-center">
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/register`}
                            className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                        >
                            {translate("PRICES_PAGE", "Try_Infinexia_Blue")}
                        </NavLink>
                    </div>
                </div>

                <div
                    className={`${
                        active == "gold" ? "" : "hidden md:!block"
                    }  bg-white pb-[5px] rounded-[10px] mt-[30px] lg:!mt-[7px] border !border-[#bebebe] `}
                >
                    <div className="bg-[#2956BE] text-center flex flex-col items-center py-[10px] px-[15px] pb-[10px] rounded-t-[10px]">
                        <h3 className=" text-xl md:!text-2xl text-white font-medium">
                            Gold
                        </h3>
                        <p className="text-base md:!text-base text-white font-normal">
                            {translate("PRICES_PAGE", "The_Most_Popular")}
                        </p>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Gold}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <p className="text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    19,90 €
                                </span>
                                / {translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className="text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block pt-[10px] pb-[0px] text-left text-base md:!text-lg font-bold md:!px-[50px] px-[40px]">
                        <div className="mb-3">
                            <CreditCardSvg />
                            {translate(
                                "PRICES_PAGE",
                                "Gold_Bank_Card_And_IBAN",
                            )}
                        </div>
                        <p className="text-left text-base font-normal">
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Pay_Link")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Devis_Factures")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("DOC_API_PAGE", "API_Service")}
                            </div>
                            <div className="mb-3">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Réduc_frais")}
                            </div>
                            <div className="mb-1">
                                <CircleMinusSvg />
                                {translate("PRICES_PAGE", "Cashback_opé")}
                            </div>
                        </p>
                    </div>
                    <div className="mt-[0px] mb-[0px] tracking-wide text-center">
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/register`}
                            className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                        >
                            {translate("PRICES_PAGE", "Try_Infinexia_Gold")}
                        </NavLink>
                    </div>
                </div>
                <div
                    className={`bg-white pb-[5px] rounded-[10px]  mt-[30px] border !border-[#bebebe] ${
                        active == "black" ? "" : "hidden md:!block"
                    } `}
                >
                    <div className=" bg-[#2956BE] text-center flex justify-center w-full py-[10px] px-[15px] rounded-t-[10px]">
                        <h3 className="text-xl md:!text-2xl text-white font-medium">
                            Black
                        </h3>
                    </div>
                    <div className="block text-center">
                        <div className="flex justify-center items-center pt-5">
                            <div className="h-auto w-[80%] md:!w-[75%] md:!h-auto p-3">
                                <img
                                    src={Card_Black}
                                    alt=""
                                    className="object-contain w-full h-full rounded-lg dark:!border dark:!border-slate-700"
                                />
                            </div>
                        </div>
                        <div className=" pt-[5px] text-lg font-medium">
                            <p className=" text-lg text-slate-500">
                                <span className="text-lg md:!text-xl font-bold text-slate-800">
                                    29,90 €
                                </span>
                                / {translate("PRICES_PAGE", "Month")}
                            </p>
                            <p className="text-sm font-normal pt-[5px]">
                                <span className="font-bold text-green-600">
                                    {translate("PRICES_PAGE", "2_Months_Free")}
                                </span>{" "}
                                {translate("PRICES_PAGE", "By_Opting")}
                            </p>
                            <p className="text-sm font-normal pb-[5px]">
                                {translate(
                                    "PRICES_PAGE",
                                    "For_Annual_Subscription",
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block pt-[10px] pb-[0px] text-left text-base md:!text-lg font-bold md:!px-[50px] px-[40px]">
                        <div className="mb-3">
                            <CreditCardSvg />
                            {translate(
                                "PRICES_PAGE",
                                "Black_Bank_Card_And_IBAN",
                            )}
                        </div>
                        <p className="text-left text-base font-normal ">
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Pay_Link")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Devis_Factures")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("DOC_API_PAGE", "API_Service")}
                            </div>
                            <div className="mb-3">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Réduc_frais")}
                            </div>
                            <div className="mb-1">
                                <CircleCheckSvg />
                                {translate("PRICES_PAGE", "Cashback_opé")}
                            </div>
                        </p>
                    </div>
                    <div className="sm:mt-0 md:!mt-[5px] tracking-wide text-center">
                        <NavLink
                            to={`${lang ? `/${lang}` : ""}/register`}
                            className="btn btn-perfect text-center text-sm md:!text-base px-10 rounded-lg ifx-bouton p-2 py-3 sm:w-2/2 w-2/2 text-white font-medium my-6"
                        >
                            {translate("PRICES_PAGE", "Try_Infinexia_Black")}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessPriceCards;
