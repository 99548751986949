import React from "react";
import loadable from "@loadable/component";

import Base from "../Layouts/Account/Base";

import PrivateRouteProvider from "./RouteProvider/PrivateRouteProvider";
import LoyaltyConfig from "../Admin/Components/PackBoosterOrder/LoyaltyConfig";

import { Outlet } from "react-router-dom";
import Subscription from "../Components/Profil/TabElements/Subscription/Subscription";
import ManageSubscription from "../Components/Profil/TabElements/Subscription/ManageSubscription";
import MyCard from "../Components/card/Card";
import Gouvernance from "../Components/Gouvernance/Gouvernance";
// import ChartCryptoPage from "../Components/Wallets/CryptoChart/ViewDetailAsset";
const ChartCryptoPage = loadable(
    () => import("../Components/Wallets/CryptoChart/ViewDetailAsset"),
);

const SwipeAsset = loadable(
    () => import("../Components/Wallets/UserAssetDetail/SwipeAsset"),
);

const Logout = loadable(() => import("../Components/Authentication/Logout"));
const SwipperProvider = loadable(
    () => import("../App/Provider/SwipperProvider"),
);

const Dashboard = loadable(() => import("../Components/Dashboard/Dashboard"));

const PnlAnalysisDashboard = loadable(
    () => import("../Components/Dashboard/PnlAnalysis/PnlAnalysisDashboard"),
);

const Profile = loadable(() => import("../Components/Profil/Profile"));

const ProfileDetail = loadable(
    () => import("../Components/Profil/TabElements/ProfileDetail"),
);
const ProfileEdit = loadable(
    () => import("../Components/Profil/TabElements/ProfileEdit"),
);
const Affiliation = loadable(
    () => import("../Components/Profil/TabElements/Affiliation"),
);
const ProfileSecurity = loadable(
    () => import("../Components/Profil/TabElements/ProfileSecurity"),
);

const Template = loadable(
    () => import("../Components/TemplateWallet/Template"),
);

const Commande = loadable(() => import("../Components/Order/PackboosterOrder"));

const ListTransactions = loadable(
    () => import("../Components/Transactions/ListTransactions"),
);

const Swap = loadable(() => import("../Components/Wallets/Swap"));

const StackingNew = loadable(
    () => import("../Components/Stacking/StackingNew"),
);

const SwpTransfert = loadable(
    () => import("../Components/Wallets/Transaction/SwpTransfert"),
);

const KycIdentity = loadable(() => import("../Components/Kyc/Kyc"));

const Withdraw = loadable(() => import("../Components/Wallets/Withdraw"));

const Deposit = loadable(() => import("../Components/Wallets/Deposit"));

const Detailsrank = loadable(
    () => import("../Components/Advantage/DetailRank/Detailsrank"),
);

const Pool = loadable(() => import("../Components/Advantage/Pool/Pool"));

const UniLevel = loadable(
    () => import("../Components/Advantage/UniLevel/UniLevel"),
);

const AffiliationUser = loadable(
    () => import("../Components/Affiliation/Affiliation"),
);

const Fidelity = loadable(() => import("../Components/Fidelity/Fidelity"));
const NewWallet = loadable(() => import("../Components/Wallets/NewWallet"));
const WalletCrypto = loadable(
    () => import("../Components/Wallets/WalletCrypto"),
);
const Achat = loadable(() => import("../Components/Wallets/Achat"));

const IndexSecurity = loadable(
    () => import("../Components/SettingSecurity/Security/IndexSecurity"),
);
const IndexSetting = loadable(
    () => import("../Components/SettingSecurity/Setting/IndexSetting"),
);
const SettingSecurity = loadable(
    () => import("../Components/SettingSecurity/SettingSecurity"),
);
const NewStaking = loadable(() => import("../Components/Stacking/NewStaking"));

const Earn = loadable(() => import("../Components/Earn/Earn"));

const ListAccount18 = loadable(
    () => import("../Components/Account18/ListAccount18"),
);
const TransactionForm = loadable(
    () => import("../Components/Account18/TransactionForm"),
);
const JoinAccount = loadable(
    () => import("../Components/JoinAccount/JoinAccount"),
);

const ListUser = loadable(
    () => import("../Admin/Components/User/List/ListUser"),
);

const UserDetail = loadable(
    () => import("../Admin/Components/User/List/TransactionForm"),
);

const Moderation = loadable(
    () => import("../Admin/Components/Moderation/Moderations"),
);

const SubAccountRequest = loadable(
    () => import("../Admin/Components/SubAccountRequest/SubAccountRequest"),
);

const AdminListTransactions = loadable(
    () => import("../Admin/Components/Transactions/ListTransactions"),
);

const ListWithdrawalRequest = loadable(
    () => import("../Admin/Components/WithdrawalRequest/ListWithdrawalRequest"),
);

const FidelityAdv = loadable(
    () => import("../Admin/Components/PackBoosterOrder/Fidelity"),
);

const ManualDepositTransaction = loadable(
    () => import("../Admin/Components/ManualDepositTransactions/List"),
);

const OrderCardTabs = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardTabs"),
);

const OrderCardAdmin = loadable(
    () => import("../Admin/Components/OrderCard/OrderCardAdmin"),
);

const BillEstimate = loadable(
    () => import("../Components/Bill-estimate/BillEstimate"),
);
const Bill = loadable(() => import("../Components/Bill-estimate/Bill/Bill"));
const Estimate = loadable(
    () => import("../Components/Bill-estimate/Estimate/Estimate"),
);

// const NewEstimate = loadable(
//     () => import("../Components/Bill-estimate/Estimate/NewEstimate"),
// );
// const NewBill = loadable(
//     () => import("../Components/Bill-estimate/Bill/NewBill"),
// );
// const BillSetting = loadable(
//     () => import("../Components/Bill-estimate/BillSetting/BillSetting"),
// );

const Invoice = loadable(
    () => import("../Components/ReactInvoice/ReactInvoice"),
);
const AccountRoutes: any = [
    {
        element: <PrivateRouteProvider />,
        children: [
            {
                path: "logout",
                element: <Logout />,
            },
            {
                element: <Base />,
                children: [
                    {
                        element: <SwipperProvider />,
                        children: [
                            {
                                path: "/dashboard",
                                element: <Dashboard />,
                                children: [],
                            },

                            {
                                path: "",
                                element: <SettingSecurity />,
                                children: [
                                    {
                                        path: "settings",
                                        element: <IndexSetting />,
                                    },
                                    {
                                        path: "referrals",
                                        element: <Affiliation />,
                                    },
                                    {
                                        path: "subscription",
                                        element: <Subscription />,
                                    },
                                    {
                                        path: "my-subscription",
                                        element: <ManageSubscription />,
                                    },
                                    {
                                        path: "actions",
                                        element: <Gouvernance />,
                                    },
                                ],
                            },

                            {
                                path: "",
                                children: [
                                    {
                                        path: "",
                                        children: [
                                            {
                                                index: true,
                                                path: "accounts",
                                                element: <NewWallet />, // <Wallets />,
                                            },
                                            {
                                                index: true,
                                                path: "cryptos",
                                                element: <WalletCrypto />,
                                            },
                                            {
                                                path: "exchange/:origin",
                                                element: <Swap />,
                                            },
                                            {
                                                path: "exchange/",
                                                element: <Swap />,
                                            },
                                            {
                                                path: "deposit/:origin",
                                                element: <Deposit />,
                                            },
                                            {
                                                path: "deposit/",
                                                element: <Deposit />,
                                            },
                                            {
                                                path: "buy/:origin",
                                                element: <Achat />,
                                            },
                                            {
                                                path: "buy/",
                                                element: <Achat />,
                                            },
                                            {
                                                path: "withdraw/:origin",
                                                element: <Withdraw />,
                                            },
                                            {
                                                path: "withdraw/",
                                                element: <Withdraw />,
                                            },
                                            {
                                                path: "swap-transaction",
                                                element: <SwpTransfert />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "savings-plans",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <Earn />,
                                    },
                                    {
                                        path: "new",
                                        element: <StackingNew />,
                                    },
                                ],
                            },
                            {
                                path: "my-savings",
                                element: <NewStaking />,
                            },
                            {
                                path: "estimates-invoices",
                                element: <BillEstimate />,
                                children: [
                                    {
                                        path: "",
                                        element: <Estimate />,
                                    },
                                    {
                                        path: "invoices",

                                        children: [
                                            {
                                                path: "",
                                                element: <Bill />,
                                            },
                                            {
                                                path: "new",
                                                element: <Invoice />,
                                            },
                                        ],
                                    },
                                    {
                                        path: "estimates",

                                        children: [
                                            {
                                                path: "",
                                                element: <Estimate />,
                                            },
                                            {
                                                path: "new",
                                                element: <Invoice />,
                                            },
                                        ],
                                    },
                                    // {
                                    //     path: "invoice",
                                    //     element: <Invoice />,
                                    // },
                                ],
                            },
                            {
                                path: "child-accounts",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListAccount18 />,
                                    },
                                    {
                                        path: "transact/:child_uid",
                                        element: <TransactionForm />,
                                    },
                                ],
                            },
                            {
                                path: "/accounts/:id",
                                element: <SwipeAsset />,
                                children: [],
                            },

                            {
                                path: "/asset/:abbrev",
                                element: <ChartCryptoPage />,
                                children: [],
                            },
                        ],
                    },
                    {
                        element: (
                            <div className={"px-[10px] md:!px-[22px]"}>
                                {" "}
                                <Outlet />{" "}
                            </div>
                        ),
                        children: [
                            {
                                path: "cards",
                                element: <Fidelity />,
                            },
                        ],
                    },
                    {
                        path: "my-cards",
                        element: <MyCard />,
                    },
                    {
                        element: (
                            <div className={"px-[22px]"}>
                                {" "}
                                <Outlet />{" "}
                            </div>
                        ),
                        children: [
                            {
                                path: "transactions",
                                element: <ListTransactions />,
                            },
                            {
                                path: "transactions/:wallet",
                                element: <ListTransactions />,
                            },
                            {
                                path: "orders",
                                element: <Commande />,
                            },
                            {
                                path: "earn",
                                element: <Earn />,
                            },
                            {
                                path: "affiliation",
                                element: <AffiliationUser />,
                            },
                            // {
                            //     path: "support",
                            //     element: <Support />,
                            // },
                            {
                                path: "wallet-online",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <NewWallet />,
                                    },
                                    {
                                        index: true,
                                        path: "",
                                        element: <WalletCrypto />,
                                    },
                                    {
                                        path: "swap/:origin",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "deposit/:origin",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "achat/:origin",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "withdraw/:origin",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "swap-transaction",
                                        element: <SwpTransfert />,
                                    },
                                ],
                            },

                            {
                                path: "switch-join-account",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <JoinAccount />,
                                    },
                                ],
                            },
                            // menu advisor
                            {
                                path: "user",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ListUser front={true} />,
                                    },
                                    {
                                        path: "transact/:id",
                                        element: <UserDetail front={true} />,
                                    },
                                ],
                            },
                            {
                                path: "moderation",
                                element: <Moderation />,
                                children: [],
                            },
                            {
                                path: "subaccounts",
                                element: <SubAccountRequest />,
                                children: [],
                            },
                            {
                                path: "all-transactions",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <AdminListTransactions />,
                                    },
                                ],
                            },
                            {
                                path: "withdrawal-request",
                                element: <ListWithdrawalRequest />,
                            },
                            {
                                path: "cards-orders",
                                children: [
                                    {
                                        path: "",
                                        element: <LoyaltyConfig />,
                                        children: [
                                            {
                                                path: "",
                                                element: <FidelityAdv />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: "deposit-request",
                                children: [
                                    {
                                        index: true,
                                        path: "",
                                        element: <ManualDepositTransaction />,
                                    },
                                ],
                            },
                            {
                                path: "order-card",
                                element: <OrderCardTabs />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <OrderCardAdmin />,
                                    },
                                    {
                                        path: ":status",
                                        index: true,
                                        element: <OrderCardAdmin />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AccountRoutes;
