import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import translator from "../../Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import { ModalContext, ModalProvider } from "../../Extra/Modal/ModalContext";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import img_virtual1 from "../../../images/Virtual-purple.png";
import img_virtual2 from "../../../images/Virtual-silver.png";
import ItemCardAccount from "../../Wallets/AddAccounts/CardAccount";
import { CARD_VIRTUEL_TYPE } from "../../../Models/UserCard";
import FormCardVirtual from "./formCard";
import { NavLink } from "react-router-dom";
import FidelityModel from "../../../Models/Fidelity";

export default function CreateCardVirtual({
    type = "MODAL",
    showTitle = true,
}: {
    type?: "CARD_VIRTUEL" | "CARD_VIRTUEL_U" | "MODAL";
    showTitle?: boolean;
}) {
    const { user } = useAuthentication();
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const [typeCard, setTypeCard] = useState<CARD_VIRTUEL_TYPE>();
    const [templateForm, setTemplateForm] = useState<React.ReactNode>(null);
    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();
    const CARD_VIRTUEL = useMemo(
        () => <FormCardVirtual cardType={CARD_VIRTUEL_TYPE.CARD_VIRTUEL} />,
        [],
    );

    const CARD_VIRTUEL_U = useMemo(
        () => <FormCardVirtual cardType={CARD_VIRTUEL_TYPE.CARD_VIRTUEL_U} />,
        [],
    );
    return (
        <div className="relative min-h-[270px]">
            {typeCard && (
                <button
                    className="absolute top-[-30px] left-0 mt-2 ml-2 z-50"
                    onClick={() => {
                        setTypeCard(undefined);
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
            )}
            {type == "MODAL" && !typeCard && (
                <>
                    <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                        <div className="flex justify-center items-center p-2 text-lg font-bold">
                            {translate("WALLET", "ADD_CARD")}
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 lg:col-span-12 ">
                            <div className="intro-y">
                                <div className="col-span-12 sm:col-span-12 lg:col-span-3 2xl:col-span-3 flex justify-center items-center">
                                    <div className="flex justify-center items-center p-2 text-lg font-bold">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "left",
                                            }}
                                        >
                                            <ItemCardAccount
                                                icon={
                                                    <img
                                                        src={UtilsService.getPulicImage(
                                                            img_virtual1,
                                                        )}
                                                        className="h-12 w-48 rounded dark:!border dark:!border-slate-700"
                                                    />
                                                }
                                                title={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD",
                                                )}
                                                titleClassName="mt-3"
                                                description={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD_TEXT",
                                                )}
                                                modalContent={""}
                                                onClick={() => {
                                                    setTypeCard(
                                                        CARD_VIRTUEL_TYPE.CARD_VIRTUEL,
                                                    );
                                                }}
                                            />
                                            <ItemCardAccount
                                                icon={
                                                    <img
                                                        src={UtilsService.getPulicImage(
                                                            img_virtual2,
                                                        )}
                                                        className="h-12 w-48 rounded dark:!border dark:!border-slate-700"
                                                    />
                                                }
                                                title={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD2",
                                                )}
                                                titleClassName="mt-3"
                                                description={translate(
                                                    "WALLET",
                                                    "VIRTUAL_CARD_TEXT2",
                                                )}
                                                modalContent={""}
                                                onClick={() => {
                                                    setTypeCard(
                                                        CARD_VIRTUEL_TYPE.CARD_VIRTUEL_U,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {typeCard === CARD_VIRTUEL_TYPE.CARD_VIRTUEL && CARD_VIRTUEL && (
                <>{CARD_VIRTUEL}</>
            )}
            {typeCard === CARD_VIRTUEL_TYPE.CARD_VIRTUEL_U &&
                CARD_VIRTUEL_U && <>{CARD_VIRTUEL_U}</>}
        </div>
    );
}
