import React, { useRef } from "react";
import translator from "../Extra/Translation/Translate";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CheckPriceSvg from "../Extra/Svg/CheckPriceSvg";
import LockPriceSvg from "../Extra/Svg/LockPriceSvg";
import CheckNonePriceSvg from "../Extra/Svg/CheckNonePriceSvg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";

interface BusinessPriceTableProps {
    active: "blue" | "black" | "gold";
    handleButtonClick: (color: "blue" | "gold" | "black") => void;
    handleSlideChange: (swiper: SwiperType) => void;
    disableDarkMode?: boolean;
}

const BusinessPriceTable: React.FC<BusinessPriceTableProps> = ({
    active,
    handleButtonClick,
    handleSlideChange,
    disableDarkMode,
}) => {
    const { translate } = translator();
    const { lang } = useParams();
    const swiperRef = useRef<SwiperType | null>(null);

    return (
        <>
            <div className={disableDarkMode ? "light-mode" : "dark-mode"}>
                {/* Contenu de la table */}
                <div
                    className={`text-xl ${disableDarkMode ? "text-black" : "dark:text-gray-400"} -z-40 pb-36 w-full px-1 md:!px-0 md:!w-[100%] max-w-7xl mx-auto`}
                >
                    <div
                        className={`bg-white mt-[5px] px-0 lg:px-24 pt-2 pb-5 rounded-lg w-full ${disableDarkMode ? "" : "dark:bg-black dark:border-2 dark:!border-slate-700 dark:!rounded-md"}`}
                        id="id-comparatif-container"
                    >
                        <table
                            className={`w-full border-collapse ${active}`}
                            id="id-comparatif"
                        >
                            {/* Comptes */}
                            <thead>
                                <tr
                                    className={`bg-white text-left text-lg md:!text-xl font-normal w-full hover:!text-black hover:!bg-white ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-gray-400 dark:hover:!bg-black"}`}
                                >
                                    <th className="w-auto py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Accountfunct",
                                        )}
                                    </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                </tr>
                            </thead>
                            <tbody
                                className={`bg-white text-base font-normal ${disableDarkMode ? "" : "dark:bg-black"}`}
                            >
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Account_With_IBAN",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>

                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Free_Bank_Card",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Internal_Transfers",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Currency_Exchanges",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Savings_Plans",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate("PRICES_PAGE", "Pay_Link")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Devis_Factures",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0 pb-3">
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Service",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            <CheckPriceSvg />
                                        </div>
                                    </td>
                                </tr>
                                {/* Limites des cartes */}
                                <tr
                                    className={`bg-white !border-t-4 !border-[#F1F5F9] text-left text-lg md:!text-xl font-normal w-full hover:!text-black hover:!bg-white ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-gray-400 dark:hover:!bg-black dark:!border-t-4 dark:!border-slate-700"}`}
                                >
                                    <th className="w-auto py-2 pl-4 px-0 pt-5">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Card_Limits",
                                        )}
                                    </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                </tr>
                                <tr
                                    className={`mt-[-15px] bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "mt-[-15px] dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Monthly_Payment_Limits",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2 500€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            10 000 €
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            25 000 €
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800"}`}
                                >
                                    <td className="py-2 pl-4 px-0 pb-3">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Monthly_Withdrawal_Limits",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            1 000€
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            2 500 €
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4 pb-3">
                                        <div className="flex items-center justify-center">
                                            10 000 €
                                        </div>
                                    </td>
                                </tr>
                                {/* Avantages */}
                                <tr
                                    className={`bg-white !border-t-4 !border-[#F1F5F9] text-left text-lg md:!text-xl font-normal w-full hover:!text-black hover:!bg-white ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-gray-400 dark:hover:!bg-black dark:!border-t-4 dark:!border-slate-700"}`}
                                >
                                    <th className="w-auto py-2 pl-4 px-0 pt-5">
                                        {translate("PRICES_PAGE", "Benefits")}
                                    </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                    <th className="w-[10%] py-2 px-4"> </th>
                                </tr>
                                <tr
                                    className={`!mt-[-15px] bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "!mt-[-15px] dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate("SUBSCRIPTION", "Api_Fees")}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            2%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1,5%
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Reductions_On",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            25%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            50%
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Discounts_On_Purchases",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            25%
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            50%
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Cashback_On",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1%
                                        </div>
                                    </td>
                                </tr>
                                <tr
                                    className={`bg-white hover:!text-black hover:!bg-gray-100 ${disableDarkMode ? "" : "dark:bg-black dark:hover:!text-white dark:hover:!bg-zinc-800 group"}`}
                                >
                                    <td className="py-2 pl-4 px-0">
                                        {translate(
                                            "PRICES_PAGE",
                                            "Cashback_On_CB",
                                        )}
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            <CheckNonePriceSvg />
                                        </div>
                                    </td>
                                    <td className="text-center py-2 px-4">
                                        <div className="flex items-center justify-center">
                                            1%
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BusinessPriceTable;
